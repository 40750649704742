import { useContext, useState } from 'react';
import styled from 'styled-components';

import { Paragraph } from '@swordhealth/ui-corporate';

import Arrow from './Arrow';
import Bar from './Bar';
import Cards from '../../shared/Cards/Cards';
import Title from './Title';
import SideModal from './SideModal';

import {
  StyledSection,
  StyledContainer,
  SubtitleContainer,
  TitlesWrapper,
  ArrowButton,
  DesktopButton,
  TabletButton,
} from './styles';

import CoveredModal from '@/app/components/core/CoveredModal';

import { products } from '@/utils/products';
import { ACTIONS, trackButtonClicked } from '@/utils/track';

import ClientContext, { ClientProvider } from '@/contexts/client.context';

export default function Hero({ content, params, showSwordLogo }) {
  const { client } = useContext(ClientContext);
  const { sentences, cards, title, label, showCTA, conditionModal, logos } = content;
  const [productSelected, setProductSelected] = useState(products.PLATFORM);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const solutionConditions = cards.map((card) => card?.solutionCondition?.data?.attributes);

  const handleButtonClick = () => {
    setModalIsOpen(!modalIsOpen);
    trackButtonClicked({
      action: ACTIONS.OPEN_DRAWER,
      buttonText: conditionModal?.buttonLabel,
      buttonLocation: 'Hero',
    });
  };

  return (
    <ClientProvider
      defaultClient={{
        clientKey: client?.clientKey,
        institutionKey: null,
        product: productSelected,
        launchDate: null,
        isActive: true,
      }}
    >
      <Bar
        selectProduct={setProductSelected}
        params={params}
        logos={logos}
        isOrganicLP
        clientKey={client?.clientKey}
        showSwordLogo={showSwordLogo}
      />
      <CoveredModal
        coveredModal={{
          title: 'First, let’s make sure you’re covered.',
          subtitle:
            'Provide the name of the employer, health plan, or organization that offers you Sword as a benefit.',
          buttonLabel: 'Check my coverage',
          placeholder: 'E.g. Domino’s, Blue Health',
        }}
      />

      <Wrapper>
        <StyledSection colored lastColored>
          <StyledContainer large>
            <TitlesWrapper>
              <Title list={sentences} title={title} />
              {(label || showCTA) && (
                <SubtitleContainer>
                  {label && <Paragraph as="h3">{label}</Paragraph>}
                  {showCTA && conditionModal && solutionConditions.length > 0 && (
                    <>
                      <SideModal
                        title={conditionModal?.title}
                        subtitle={conditionModal?.subtitle}
                        modalIsOpen={modalIsOpen}
                        setModalIsOpen={setModalIsOpen}
                        solutionConditions={solutionConditions}
                        client={client}
                      />
                      <DesktopButton variant="white" onClick={handleButtonClick}>
                        {conditionModal?.buttonLabel}
                        <ArrowButton />
                      </DesktopButton>
                    </>
                  )}
                </SubtitleContainer>
              )}
            </TitlesWrapper>
            {cards && (
              <Cards
                cards={cards}
                selectProduct={setProductSelected}
                params={params}
                client={client?.clientKey}
              />
            )}
            {showCTA && conditionModal && solutionConditions.length > 0 && (
              <TabletButton variant="white" onClick={handleButtonClick}>
                <>
                  {conditionModal?.buttonLabel}
                  <ArrowButton />
                </>
              </TabletButton>
            )}
          </StyledContainer>
        </StyledSection>
        <Arrow />
      </Wrapper>
    </ClientProvider>
  );
}

const Wrapper = styled.div`
  position: relative;
`;
