import { useContext } from 'react';
import styled, { css } from 'styled-components';

import { Container } from '@/components/ui/flexbox';

import { Button, sword } from '@swordhealth/ui-corporate';
import { products } from '@/utils/products';
import ClientContext from '@/contexts/client.context';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';
import { useInView } from 'react-intersection-observer';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import Image from 'next/image';
import { Logos } from '@/app/_components/shared/Logos/Logos';

export default function Bar({
  selectProduct,
  navigation,
  portugueseHealthPlan = false,
  params,
  logos,
  clientKey,
  isOrganicLP,
  showSwordLogo,
}) {
  const { setProduct } = useContext(ClientContext);
  const isMobile = useBetterMediaQuery(`(max-width: ${sword.breakpoints.maxSm})`);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: isMobile ? '30px' : '0px',
  });

  const handleLoginClick = () => {
    const destination = params
      ? `${process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL + '?' + params}`
      : `${process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL}`;

    trackButtonClicked({
      action: ACTIONS.REDIRECT_TO_SIGN_IN,
      buttonLocation: 'header',
      buttonText: 'Sign in here',
      buttonType: BUTTON_TYPES.NAVIGATION,
    });

    window.location.assign(destination);
  };

  const handleGetStartedClick = () => {
    const destination = `/eligibility-check/${
      clientKey ? `?client=${clientKey}&${params}` : params ? `?${params}` : ''
    }`;

    trackButtonClicked({
      action: portugueseHealthPlan ? ACTIONS.REDIRECT_TO_FORM : ACTIONS.REDIRECT_TO_COVERAGE,
      buttonType: BUTTON_TYPES.NAVIGATION,
      buttonText: 'Get started',
      destination,
      buttonLocation: 'bar',
    });

    window.open(destination, '_blank', 'noopener,noreferrer');

    if (!portugueseHealthPlan) {
      selectProduct(products.PLATFORM);
      setProduct(products.PLATFORM);
    }
  };

  return (
    <div ref={ref}>
      <Wrapper $white={!inView} $notSticky={portugueseHealthPlan && !navigation?.buttonLabel}>
        <Container large>
          <WrapperInner $extraPadding={portugueseHealthPlan}>
            {!logos && (
              <Image
                loading="lazy"
                alt="Sword logo"
                src="/sword__colored.svg"
                height={isMobile ? 24 : 28}
                width={isMobile ? 100 : 116}
              />
            )}
            {logos && (
              <Logos
                logos={logos}
                noPadding
                centered={isMobile && logos?.length >= 1 && showSwordLogo}
                customHeight={isMobile ? 22 : 32}
                showSwordLogo={showSwordLogo}
              />
            )}

            {!clientKey || (!isMobile && clientKey) ? (
              <Buttons>
                {!portugueseHealthPlan && (
                  <Button variant="secondary" size="sm" onClick={handleLoginClick}>
                    Login
                  </Button>
                )}
                {(isOrganicLP || navigation?.buttonLabel) && (
                  <Button variant="primary" size="sm" onClick={handleGetStartedClick}>
                    {navigation?.buttonLabel || 'Get started'}
                  </Button>
                )}
              </Buttons>
            ) : null}
          </WrapperInner>
        </Container>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.15s ease-in-out;
  padding: 20px 0;

  ${(props) =>
    props.$notSticky &&
    css`
      position: absolute;
      transition: none;
    `};

  ${(props) =>
    props.$white &&
    css`
      background-color: #f7f4f2;
      box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;
    `};
`;

const WrapperInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  img {
    align-self: center;
  }

  ${(props) =>
    props.$extraPadding &&
    css`
      @media (min-width: 1279px) {
        padding-left: 27px;
        padding-right: 30px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        padding-left: 0;
        padding-right: 0;
      }
    `};
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  text-wrap: nowrap;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    > *:nth-child(2) {
      display: none;
    }
    > *:first-child {
      padding: 9px 24px;
      height: 40px;
    }
  }
`;
