import { useSearchParams } from 'next/navigation';
import Image from 'next/image';

import { Button, Heading, Paragraph, SectionHeader } from '@swordhealth/ui-corporate';

import {
  Wrapper,
  CustomModal,
  CloseButton,
  IconClose,
  Content,
  ButtonWrapper,
  UnitHeader,
  ImageWrapper,
  HeaderContent,
} from './styles';
import { ArrowButton } from '../styles';

import getImgSrcWorkaround from '@/utils/images';
import { products } from '@/utils/products';

import { getClient } from '@/services/client.service';
import { compareDates } from '@/utils/dates';
import { getOnboardingURL } from '@/utils/onboarding-urls';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

export default function SideModal({
  title,
  subtitle,
  solutionConditions,
  modalIsOpen,
  setModalIsOpen,
  client,
}) {
  const searchParams = useSearchParams();
  const params = searchParams.toString();

  const handleConditionClick = async (condition, solution, productKey) => {
    document.documentElement.classList.remove('ui-html-no-scroll');
    const programType = products[productKey.toUpperCase()]?.param;
    const conditionLabel = condition?.label;
    const encodedCondition = btoa(conditionLabel).replace(/=+$/, '');

    if (!client?.clientKey) {
      const eligibilityURL = params
        ? `/eligibility-check?solution=${productKey}&cond=${encodedCondition}&hide_get_started=true&program_type=${programType}&${params}${
            client?.clientKey ? `&client=${client?.clientKey}` : ''
          }`
        : `/eligibility-check?solution=${productKey}&cond=${encodedCondition}&hide_get_started=true&program_type=${programType}${
            client?.clientKey ? `&client=${client?.clientKey}` : ''
          }`;
      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_COVERAGE,
        buttonType: BUTTON_TYPES.NAVIGATION,
        buttonText: condition?.button_label
          ? `${condition?.button_label} - ${solution}`
          : `Get started - ${solution}`,
        destination: eligibilityURL,
        buttonLocation: 'card',
      });
      setTimeout(() => {
        window.open(eligibilityURL, '_blank', 'noopener,noreferrer');
      }, 0);
    } else {
      const fetchedClient = await getClient({
        client: client?.clientKey,
        productKey: productKey,
      });

      if (fetchedClient && compareDates(fetchedClient?.launchDate)) {
        return setTimeout(() => {
          window.open(
            `/waiting-list?client=${fetchedClient.clientKey}`,
            '_blank',
            'noopener,noreferrer',
          );
        });
      }
      const url = getOnboardingURL({
        client: fetchedClient,
        programType: products[productKey.toUpperCase()].param,
        query: Object.fromEntries(params),
        conditionLabelParam: encodedCondition,
      });

      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_ONBOARDING,
        buttonType: BUTTON_TYPES.NAVIGATION,
        buttonText: condition?.button_label ?? `Get started - ${productKey}`,
        destination: url,
        buttonLocation: 'form',
      });
      setTimeout(() => {
        window.open(url, '_blank', 'noopener,noreferrer');
      }, 0);
    }
  };
  return (
    <CustomModal
      centeredContent={false}
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      showCloseButton={false}
    >
      <Wrapper id="modal-react">
        <HeaderContent>
          <SectionHeader
            title={title}
            titleSize="2xl"
            id="modal-title"
            description={subtitle}
            xAlign="1"
          />
          <CloseButton
            aria-label="Close"
            type="button"
            onClick={() => setModalIsOpen(false)}
            data-testid="closeModal"
          >
            <IconClose />
          </CloseButton>
        </HeaderContent>
        <Content>
          {solutionConditions?.map((solution, solIndex) => {
            const { product, conditions } = solution;
            const productKey = product?.productKey || product?.data?.attributes?.productKey;
            if (conditions.length > 0)
              return (
                <>
                  <UnitHeader>
                    <ImageWrapper>
                      <Image
                        id={`solution-logo-${solIndex}`}
                        fill
                        src={
                          product?.logo
                            ? getImgSrcWorkaround(product?.logo?.url)
                            : products[productKey.toUpperCase()]?.logo
                        }
                      />
                    </ImageWrapper>
                    <Heading id={`solution-title-${solIndex}`} as="h3" size="xl">
                      {solution?.title}
                    </Heading>
                  </UnitHeader>
                  <ButtonWrapper>
                    {conditions.map((condition, index) => {
                      return (
                        <>
                          <Button
                            id={`condition-button-${solIndex}-${index}`}
                            key={`conditions_${productKey}_${index}`}
                            variant="text"
                            onClick={() => handleConditionClick(condition, solution, productKey)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <>
                              <Paragraph id={`condition-label-${solIndex}-${index}`} size="lg">
                                {condition.label}
                              </Paragraph>
                              <ArrowButton id={`condition-label-${solIndex}-${index}`} />
                            </>
                          </Button>
                        </>
                      );
                    })}
                  </ButtonWrapper>
                </>
              );
          })}
        </Content>
      </Wrapper>
    </CustomModal>
  );
}
